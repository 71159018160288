import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@helpers/i18n.js";

import Settings from "../settings.json";

Vue.use(VueRouter);

// services
// import AutoLogin from "./services/AutoLogin";

const routes = [
  {
    name: "app-start",
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "app-start" */ "../layouts/default.vue"),
    children: [
      {
        name: "home",
        path: "",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Chat.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug/:roomId",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Chat.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug/:roomId/:serviceIds",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Chat.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug/:roomId",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Chat.vue"),
      },
      {
        name: "track",
        path: "track/:id",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Track.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

router.beforeEach((to, from, next) => {
  document.title =
    i18n.t(`navigation.${to.name}`) + " | " + Settings.projectName;
  next();
});
export default router;
